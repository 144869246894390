.preNav{
    background-color:black;
    display: grid;
    grid-template-columns: 5fr 2fr;
    color: white;
}

.preNav > div{
    padding: 0 4vmax;
    display: flex;
    align-items: center;
}

.preNav > div > span {
    color: rgba(180, 180, 180, 0.644);
    font-size: 0.8vmax;
    font-weight: 100;
}

.preNav > div svg{
    fill:rgb(212, 205, 205);
}

.preNav > div svg:hover{
    color: white;
}

.preNav > div > a {
    color: rgb(180, 180, 180);
    font-size: 0.9vmax;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-weight: 300;
    margin: 1vmax;
    text-decoration: none;
}

.preNav > div > a:hover{
    color: white;
}

